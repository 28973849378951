import React, { useEffect, useContext } from 'react'

import Layout from '../components/layout/Layout'
import { SEO } from '../components/SEO'

import { myContext } from '../provider'
import { About } from '../components/about/About'

const LicensingPage: React.FunctionComponent = () => {
  const context = useContext(myContext)
  useEffect(() => {
    context.updateActivePage('about')
  }, [context])

  return (
    <>
      <Layout>
        <SEO title={'About'} />
        <About />
      </Layout>
    </>
  )
}

export default LicensingPage
