import { graphql, useStaticQuery } from 'gatsby'
import { IImage } from './IImage'
import { extractFileNameWithoutExtension } from '../helpers/extractFileNameWithoutExtension'

export const useImage = (pathname: string): IImage | null => {
  const name = extractFileNameWithoutExtension(pathname)

  const data = useStaticQuery(graphql`
    query getImage {
      allFile(filter: { sourceInstanceName: { eq: "uploads" } }) {
        edges {
          node {
            relativePath
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const uploads: IImage[] = data.allFile.edges
  const image = uploads.filter(item => item.node.relativePath.includes(name))[0]

  return image ?? null
}
