import React from 'react'
import Img from 'gatsby-image'

import styles from './about.module.css'

import { intro, text, clients, contact_text } from '../../../content/data/about.json'
import { Markdown } from '../markdown/Markdown'
import { useImage } from '../../graphql/useImage'

export const About: React.FC = () => {
  const clientLogos = useImage(clients)

  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <Markdown content={intro} />
      </div>
      <div className={styles.about}>
        <Markdown content={text} />
      </div>
      {clientLogos ? (
        <div className={styles.clients}>
          <Img fluid={clientLogos.node.childImageSharp.fluid} alt={'DODODO Studio clients'} />
        </div>
      ) : null}
      <div id="contact" className={styles.contact}>
        <h2>Contact</h2>
        <Markdown content={contact_text} />
        <ul className={styles.social}>
          <li key="instagram">
            <a href="https://instagram.com/dododostudio" target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className={styles.instagram}
              >
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
              </svg>
            </a>
          </li>
          <li key="twitter">
            <a href="https://twitter.com/DODODOStudio" target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className={styles.twitter}
              >
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
